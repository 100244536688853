import { Link } from "@remix-run/react";
import { cn } from "~/utils/cn";

const variations = {
  primary:
    "text-charcoal-300 hover:text-bright underline underline-offset-4 decoration-charcoal-500 hover:decoration-primary transition inline-flex gap-0.5 items-center group",
  secondary:
    "text-dimmed transition underline underline-offset-2 decoration-dimmed/50 hover:decoration-dimmed inline-flex gap-0.5 items-center group",
} as const;

type TextLinkProps = {
  href?: string;
  to?: string;
  className?: string;
  variant?: keyof typeof variations;
  children: React.ReactNode;
  target?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function TextLink({
  href,
  to,
  children,
  className,
  variant = "primary",
  target = "_self",
  ...props
}: TextLinkProps) {
  const classes = variations[variant];
  return to ? (
    <Link to={to} className={cn(classes, className)} {...props}>
      {children}{" "}
    </Link>
  ) : href ? (
    <a
      href={href}
      target={target}
      className={cn(classes, className)}
      {...props}
    >
      {children}{" "}
    </a>
  ) : (
    <span>Need to define a path or href</span>
  );
}
